import axios from "axios";

export const checkEmail = async (clinic: string, email: string) => {
    try {
        const { data } = await axios.post(route("check-email", { clinic }), {
            email,
        });
        return data;
    } catch (e) {
        return { error: true };
    }
};

export const checkEmailMultipleInstance = async (email: string) => {
    try {
        const { data } = await axios.post(route("check-email-multiple"), {
            email,
        });
        return data;
    } catch (e) {
        return { error: true };
    }
};

export const resendInvite = async (clinic: string, email: string) => {
    try {
        const { data } = await axios.post(route("resend-invite", { clinic }), {
            email,
        });
        return data;
    } catch (e) {
        return { error: true };
    }
};

export const acceptCommunicationPreference = async (
    clinic: string,
    request: any,
) => {
    try {
        const { data } = await axios.post(
            route("profile.accept-communication-preference", { clinic }),
            request,
        );
        return data;
    } catch (e) {
        return { error: true };
    }
};
